import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import store from './store/index';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'
/* Theme variables */
import './theme/variables.css';
import useFirebaseAuth from "./hooks/firebase-auth";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';

const { authCheck } = useFirebaseAuth();


//set vars for process.env
const firebaseConfig = {
  apiKey: "AIzaSyCpN9y4E3UHg5NTKdMULMbPgJwTZ2UgnOo",
  authDomain: "psiquiatria-9722e.firebaseapp.com",
  databaseURL: "https://psiquiatria-9722e-default-rtdb.firebaseio.com",
  projectId: "psiquiatria-9722e",
  storageBucket: "psiquiatria-9722e.appspot.com",
  messagingSenderId: "758499085929",
  appId: "1:758499085929:web:6e919289f084c9c91fdcf8"
};

// if firebase isn't already initialize, initialize using the above credentials
if (!firebase.apps.length) {
firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();
const auth = firebase.auth();
/*
if (location.hostname === 'localhost') {
  db.useEmulator("localhost", 8080);
  auth.useEmulator("http://localhost:9099");
  // add more services as described in the docs: https://firebase.google.com/docs/emulator-suite/connect_firestore
}
*/
// router.beforeEach(async (to, from, next) => {
//   const authentication = to.meta.requiresAuth;
//   if (authentication && !await firebase.getCurrentUser()) next('/login')
//   else if (!authentication && await firebase.getCurrentUser()) next('/profile')
//   else next()
// })

authCheck()
  .then(() => {
    const app = createApp(App)
      .use(IonicVue)
      .use(store)
      .use(router)
      .use(SetupCalendar, {})
      .component('Calendar', Calendar)
      .component('DatePicker', DatePicker)
      router.isReady().then(() => {
      app.mount('#app');
    });
    app.config.ignoredElements = [/^ion-/];
  })


export  {db,auth}