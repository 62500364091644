<template>
  <ion-app class="slide1">
    <ion-router-outlet />
  </ion-app>
</template>

<script >
import { IonApp, IonRouterOutlet, loadingController } from "@ionic/vue";
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import firebase from "firebase/compat/app";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  // desde el main.js mando estos objetos

  setup() {
    // y usando el manejo de estados registro el user como variable global
    const $store = useStore();
    const loggedUser = ref("");
    return {};
  },
});
</script>