import { createRouter, createWebHistory } from '@ionic/vue-router';
import Layout from '../views/Layout.vue'
import firebase from 'firebase/compat/app'
import { auth, db } from "../main";
import user from '../store/modules/user';

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        redirect: '/home'
      },
      {
        path: 'home',
        component: () => import('@/views/HomeTab.vue'),
        beforeEnter: async(to, from, next) => 
        {
          if (await auth.currentUser) {
            next('profile');
          } else {
            next();
          }   
        } 
      },
      {
        path: 'login',
        component: () => import('@/views/LoginPage.vue'),
        beforeEnter: async(to, from, next) => 
        {
          if (await auth.currentUser) {
            next('profile');
          } else {
            next();
          }   
        } 
      },
      {
        path: 'register',
        component: () => import('@/views/RegisterPage.vue'),
        beforeEnter: async(to, from, next) => 
        {
          if (await auth.currentUser) {
            next('profile');
          } else {
            next();
          }   
        } 
      },
      {
        path: 'dates',
        component: () => import('@/views/CalendarPage.vue'),
        beforeEnter: async(to, from, next) => 
        {
          if (!await auth.currentUser) {
            next('home');
          } else {
            next();
          }   
        } 
      },
      {
        path: 'profile',
        component: () => import('@/views/ProfilePage.vue'),
        beforeEnter: async(to, from, next) => 
        {
          if (!await auth.currentUser) {
            next('home');
          } else {
            next();
          }   
        } 
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
