<template>
  <ion-page>
    <ion-header v-if="isLoggedIn">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button size="small" shape="round" @click="openMenu()"
            ><ion-icon :icon="menuOutline"></ion-icon
          ></ion-button>
        </ion-buttons>
        <ion-title>Inicio</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-menu menu-id="app-menu" content-id="main-content" v-if="userData">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menú</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list v-if="userData">
          <div v-if="userData.permission == 'cliente'">
            <ion-item button @click="goToRoute('profile')">
              <ion-icon :icon="homeOutline" slot="start"></ion-icon>
              <ion-label>Inicio</ion-label>
            </ion-item>
            <ion-item button @click="goToRoute('calendar')">
              <ion-icon :icon="calendar" slot="start"></ion-icon>
              <ion-label>Calendario</ion-label>
            </ion-item>
            <ion-item button @click="goToRoute('help')">
              <ion-icon :icon="helpCircle" slot="start"></ion-icon>
              <ion-label>Acerca de la aplicación</ion-label>
            </ion-item>
            <ion-item button @click="logout">
              <ion-icon :icon="logOut" slot="start"></ion-icon>
              <ion-label>Cerrar Sesión</ion-label>
            </ion-item>
          </div>
          <div v-if="userData.permission == 'doctor'">
            <ion-item button @click="goToRoute('/profile')">
              <ion-icon :icon="homeOutline" slot="start"></ion-icon>
              <ion-label>Inicio</ion-label>
            </ion-item>
            <ion-item button @click="goToRoute('dates')">
              <ion-icon :icon="calendar" slot="start"></ion-icon>
              <ion-label>Citas</ion-label>
            </ion-item>
            <ion-item button @click="goToRoute('config')">
              <ion-icon :icon="cog" slot="start"></ion-icon>
              <ion-label>Configuraciones del sistema</ion-label>
            </ion-item>
            <ion-item button @click="goToRoute('publications')">
              <ion-icon :icon="helpCircle" slot="start"></ion-icon>
              <ion-label>Publicaciones</ion-label>
            </ion-item>
            <ion-item button @click="logout">
              <ion-icon :icon="logOut" slot="start"></ion-icon>
              <ion-label>Cerrar Sesión</ion-label>
            </ion-item>
          </div>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-tabs>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonToolbar,
  IonTitle,
  IonItem,
  IonList,
  IonTabs,
  IonContent,
  IonLabel,
  IonHeader,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  menuController,
  IonMenu,
} from "@ionic/vue";
import {
  ellipse,
  square,
  logOut,
  triangle,
  cog,
  brush,
  menuOutline,
  helpCircle,
  homeOutline,
  people,
  calendar,
} from "ionicons/icons";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { auth, db } from "../main";
import { getAuth, signOut } from "firebase/auth";
import { useRouter, useRoute } from "vue-router";

export default {
  components: {
    IonLabel,
    IonToolbar,
    IonTitle,
    IonItem,
    IonList,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonContent,
    IonHeader,
    IonRouterOutlet,
    IonMenu,
  },
  setup() {
    const $store = useStore();
    // const isLoggedIn = ref("");
    const router = useRouter();
    const isLoggedIn = computed(() => $store.getters["user/getUser"]);
    const userData = computed(()=> $store.getters["user/getUserData"]);
    const logout = async () => {
      await signOut(auth);
      router
        .push("home")
        .then(()=>{
          $store.dispatch("user/clearUser");
        })
        .catch((error) => console.log("error", error));
    };
    const goToRoute = (route) => {
      router.push(route);
    };
    const openMenu = () => {
      menuController.open("app-menu");
    };
    return {
      isLoggedIn,
      ellipse,
      square,
      triangle,
      menuOutline,
      calendar,
      helpCircle,
      homeOutline,
      logOut,
      brush,
      userData,
      cog,
      people,
      logout,
      goToRoute,
      openMenu,
    };
  },
};
</script>

