
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'
import store from '../store/index'

export default function() {

//   const login = (username, password) => {
//     state.loading = true;
//     return firebase
//       .auth()
//       .signInWithEmailAndPassword(username, password)
//       .then(
//         async (user) => {
//           state.error = null;
//           state.loading = false;
//           state.user = user;
//           state.userData = await getUserData();
//           return user;
//         },
//         (error) => {
//           state.error = error;
//           state.loading = false;
//           throw error;
//         }
//       )
//       .catch((error) => {
//         // Handle Errors here.
//         state.error = error;
//         state.loading = false;
//         throw error;
//       });
//   };


//   const logout = () => {
//     return firebase
//       .auth()
//       .signOut()
//       .then(
//         () => {
//           state.error = null;
//           state.loading = false;
//           state.user = null;
//           state.userData = null;
//         },
//         (error) => {
//           state.error = error;
//           state.loading = false;
//         }
//       )
//       .catch((error) => {
//         // Handle Errors here.
//         state.error = error;
//         state.loading = false;
//       });
//   };

  // El hook para firebase
  const authCheck = () => {
    return new Promise((resolve, reject) => {
      //state.loading = true;
      store.commit('user/saveLoading', true);
        firebase.auth().onAuthStateChanged(async (_user) => {
          if (_user) {
            // state.user = _user;
            // state.userData = await getUserData();
            store.commit('user/saveUser', _user.multiFactor.user);
            store.dispatch('user/updateUserData', _user.multiFactor.user.email);
            store.dispatch('user/allConsultas', _user.multiFactor.user.email);
          } else {
            console.log('heyyy');
            // state.user = null;
          }
        //   state.loading = false;
        //   state.initialized = true;
          resolve(true);
        });
    });
  };

  return {
    authCheck,
  };
}
