
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'
import { auth, db } from "../../main";

const state = () => ({
  user: null,
  userData: null,
  error: false,
  loading: false,
  allUsers: null,
  allDoctors: null,
  consultas: null,
  consultasDoctor: null,
  pacienteConsultas: null,
});

const getUsers = async() =>{
  return await db.collection("users").get();
};

const getData = async (email) => {
  return await db.collection("users")
  .where("email", "==", email)
  .get()
};


const agendar = async (doctorEmail, pacienteEmail, id, info) => {
  return await db.collection("users")
  .doc(doctorEmail)
  .collection("consultas")
  .doc(id)
  .update({
    agendado: true,
    paciente: pacienteEmail,
    info: info
  })
}


const login = (username, password) => {
  state.loading = true;
  return firebase
    .auth()
    .signInWithEmailAndPassword(username, password);
};

const createUser = (email, password) => {
  state.loading = true;
  return auth
    .createUserWithEmailAndPassword(email, password);
};

const getters = {
  getUser(state){
    return state.user;
  },
  getUserData(state){
    return state.userData;
  },
  getAllUsers(state){
    return state.allUsers;
  },
  getAllDoctors(state){
    return state.allDoctors;
  },
  getAllConsultas(state){
    return state.consultas;
  },
  getDoctorConsultas(state){
    return state.consultasDoctor;
  },
  getPacienteConsultas(state){
    return state.pacienteConsultas;
  },
  getLoading(state){
    return state.loading;
  },
  getError(state){
    return state.error;
  },
};
 
const actions = {
  async updateUser({commit}, payload){
    commit('saveUser');
  },
   
  async updateUserData({commit}, email){
    await getData(email)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let usuario = doc.data();
        console.log(usuario);
        commit('saveUserData', usuario);
      });
    });
  },

  async crearConsulta({commit}, obj){
    const docRef = db.collection("consultas")
    return await docRef
    .add(obj)
    .then((doc)=>{
      let {id} = doc;
      return db.collection("consultas")
      .doc(id)
      .update({
        key: id
      })
    })
  },

  async agendarConsulta({commit}, obj){

    const docRef = db.collection("consultas")
    .doc(obj.key);

    return await docRef
    .update({
      agendado: true,
      pacienteNombre: obj.pacienteNombre,
      pacienteInfo: obj.pacienteInfo,
      pacienteEmail: obj.pacienteEmail
    })
    .then(() => {
      return docRef.get()
      .then(doc => {
        if (!doc.exists) {
          console.log("No such document!"); 
        } else {
          let consulta = doc.data();
          console.log(consulta);
        }
      })
    })
    .catch(error => {
      console.log(error);
    });

  },

  async updateFields({commit}, _user){
    await getData(_user.oldEmail)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.update({
          nombre: _user.nombres,
          apellidos: _user.apellidos,
          edad: _user.edad,
          telefono: _user.telefono,
          sexo: _user.sexo,
          email: _user.email,
          password: _user.password
        });
        let usuario = doc.data();
        commit('saveUserData', usuario);
      });
    })
    // .catch((error) => {
    //   console.log("Error getting documents: ", error);
    // });
  },

  async sendAnswers({commit}, _form){
    console.log(_form);
    await getData(_form.email)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.update({
          formulario: _form
        });
        let usuario = doc.data();
        commit('saveUserData', usuario);
      });
    })
  },

  async clearUser({commit}){
    commit('clear');
  },
  
  async allConsultas({commit}){
    const getRef = db.collection("consultas")
    .where("agendado", "==", false);

    return await getRef
    .get()
    .then((consultas) =>{
      let results = consultas.docs.map(doc => doc.data());
      results.forEach(s => {
        if(s.dates != null){
          s.dates.start = new Date(s.dates.start.seconds * 1000);
          s.dates.end = new Date(s.dates.end.seconds * 1000);
          s.popover.desde = s.dates.start.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });

          s.popover.hasta = s.dates.end.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });

        }
      });
      console.log(results);
      commit('saveConsultas', results);
    })
  },

  async allConsultasByDoctor({commit}, obj){
    console.log(obj);
    const getRef = await db.collection("consultas")
    .where("doctorEmail", "==", obj.email)
    .where("agendado", "==", obj.agendado);

    return await getRef
    .get()
    .then((consultas) =>{
      let results = consultas.docs.map(doc => doc.data());
      results.forEach(s => {
        if(s.dates != null){
          s.dates.start = new Date(s.dates.start.seconds * 1000);
          s.dates.end = new Date(s.dates.end.seconds * 1000);
          s.popover.desde = s.dates.start.getHours() + ":"+ s.dates.start.getMinutes();
          s.popover.hasta = s.dates.end.getHours() + ":" + s.dates.end.getMinutes();
          s.desde = s.dates.start.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          s.hasta = s.dates.end.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        }
      });
      console.log(results);
      commit('saveDoctorConsultas', results);
    })
  },

  async allPacienteConsultas({commit}, email){
    const docRef = await db.collection("consultas")
    .where("pacienteEmail", "==", email)
    .where("agendado", "==", true);

    return await docRef
    .get()
    .then((querySnapshot)=>{
      let arr =[] ;
      querySnapshot.forEach((document) => {
        arr.push(document.data());
      });
      arr.forEach(s => {
        s.dates.start = new Date(s.dates.start.seconds * 1000);
        s.dates.end = new Date(s.dates.end.seconds * 1000);
        s.popover.desde = s.dates.start.getHours() + ":"+ s.dates.start.getMinutes();
        s.popover.hasta = s.dates.end.getHours() + ":" + s.dates.end.getMinutes();
        s.desde = s.dates.start.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        s.hasta = s.dates.end.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      })

      console.log(arr);
      commit('savePacienteConsultas', arr);
    });  
  },

  async allDoctors({commit}){
    const docRef = db.collection("users")
    .where("permission", "==", "doctor");

    return await docRef
    .get()
    .then((users) =>{
      console.log(users);
      let doctors = users.docs.map(doc => doc.data());
      console.log(doctors);
      commit('saveAllDoctors', doctors);
    })
  },

  async allUsers({commit}){
    await getUsers()
    .then((users) =>{
      let allUsers = users.docs.map(doc => doc.data());
      console.log(allUsers);
      commit('saveAllUsers', allUsers);
    })
  },

  async loginUser({commit}, _user) {
    commit('saveLoading', true);
    await login(_user.email, _user.password)
    .then(
      async (user) => {
        commit('saveError', false);
        commit('saveLoading', false);
        await getData(_user.email)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let usuario = doc.data();
            commit('saveUserData', usuario);
          });
        });
      },
      (error) => {
        console.log(error);
        commit('saveError', error);
        commit('saveLoading', false);
        throw error;
      }
    )
    .catch((error) => {
      console.log(error);
      commit('saveError', error);
      commit('saveLoading', false);
      throw error;
    });
  },

  async registerUser({commit}, _user){
    commit('saveLoading', true);
    await createUser(_user.email, _user.password)
    .then((authentication) => {
      const user = auth.currentUser;

      db.collection("users").doc(user.email).set({
        email: _user.email,
        password: _user.password,
        edad: _user.edad,
        sexo: _user.sexo,
        nombres: _user.nombres,
        apellidos: _user.apellidos,
        telefono: _user.telefono,
        permission: "cliente",
        profile: false,
      });

      // user.sendEmailVerification();
    })
    .catch((error) => {
      commit('saveError', error);
      commit('saveLoading', false);
    });
  }
};
const mutations = {
  saveUser(state, payload){
    state.user = payload;
  },
  saveAllUsers(state, payload){
    state.allUsers = payload;
  },
  saveConsultas(state, payload){
    state.consultas = payload;
  },
  saveDoctorConsultas(state, payload){
    state.consultasDoctor = payload;
  },
  savePacienteConsultas(state, payload){
    state.pacienteConsultas = payload;
  },
  saveAllDoctors(state, payload){
    state.allDoctors = payload;
  },
  clear(state){
    state.user = null;
  },
  saveUserData(state, payload){
    state.userData = payload;
  },
  saveError(state, payload){
    state.error = payload;
  },
  saveLoading(state, payload){
    state.loading = payload;
  }
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}